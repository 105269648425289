import { createI18n, type I18nOptions } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { installWaimoLocales } from '@finetic/waimo-ui'
import { nl } from 'yup-locales'
import { setLocale } from 'yup'
import { setDefaultOptions } from 'date-fns'
import { nl as dateFnsNl } from 'date-fns/locale'

const locale: string = import.meta.env.VITE_DEFAULT_LOCALE

// Number formats
const numberFormats: I18nOptions['numberFormats'] = {
  [locale]: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal',
      maximumSignificantDigits: 2,
      maximumFractionDigits: 1,
    },
    percentage: {
      style: 'percent',
      maximumSignificantDigits: 3,
      maximumFractionDigits: 1,
      minimumSignificantDigits: 2,
    },
    integer: {
      style: 'decimal',
      maximumFractionDigits: 0,
    },
  },
}

// Date formats
const datetimeFormats: I18nOptions['datetimeFormats'] = {
  [locale]: {
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

// Date format for Date-FNS
setDefaultOptions({
  locale: dateFnsNl,
  weekStartsOn: 1,
  firstWeekContainsDate: 4,
})

// Yup form validation messages
setLocale(nl)

export default createI18n({
  locale: locale,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages: installWaimoLocales(messages),
  numberFormats,
  datetimeFormats,
})
