import type { ProductType } from './productType'

export enum RolePermission {
  ADMIN = 'admin',
  ALLOCATION_READ = 'allocation:read',
  ALLOCATION_WRITE = 'allocation:write',
  ALLOCATION_REQUEST_READ = 'allocation_request:read',
  ALLOCATION_REQUEST_WRITE = 'allocation_request:write',
  CLIENT_READ = 'client:read',
  CLIENT_WRITE = 'client:write',
  CONTRACT_READ = 'contract:read',
  CONTRACT_WRITE = 'contract:write',
  CREDITOR_READ = 'creditor:read',
  CREDITOR_WRITE = 'creditor:write',
  DEBTOR_READ = 'debtor:read',
  DEBTOR_WRITE = 'debtor:write',
  CLAIM_READ = 'claim:read',
  CLAIM_WRITE = 'claim:write',
  I_BERICHT_READ = 'i_bericht:read',
  I_BERICHT_WRITE = 'i_bericht:write',
  MUNICIPALITY_READ = 'municipality:read',
  MUNICIPALITY_WRITE = 'municipality:write',
  SUPPLIER_READ = 'supplier:read',
  SUPPLIER_WRITE = 'supplier:write',
  PAYMENT_READ = 'payment:read',
  PAYMENT_WRITE = 'payment:write',
  PRODUCT_READ = 'product:read',
  PRODUCT_WRITE = 'product:write',
  PRODUCT_GROUP_READ = 'product_group:read',
  PRODUCT_GROUP_WRITE = 'product_group:write',
  QUOTATION_READ = 'quotation:read',
  QUOTATION_WRITE = 'quotation:write',
  ROLE_MANAGEMENT_READ = 'role_management:read',
  ROLE_MANAGEMENT_WRITE = 'role_management:write',
  USER_READ = 'user:read',
  USER_WRITE = 'user:write',
}

export type RoleType = {
  id: string
  name: string
  permissions: Array<RolePermission>
  products?: Array<Pick<ProductType, 'id' | 'name'>>
}

export type RoleTypeFlat = {
  id: string
  name: string
  permissions: Array<RolePermission>
  products: Array<string> //ID
}
