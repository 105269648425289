<template>
  <div
    class="chat-list"
    :class="{ 'chat-list--in-communication-tab': inCommunicationTab }"
  >
    <WaimoLoader v-if="loading" />
    <Section
      v-else
      :text="
        t(
          'chats.dashboard.title',
          inCommunicationTab ? entityChats.length : userChats.length,
        )
      "
    >
      <template #headerRight>
        <WaimoIconButton
          v-if="inCommunicationTab"
          icon="/icons/01-Interface Essential/43-Remove-Add/add.svg"
          @click="$emit('addChat')"
        />
      </template>
      <div class="chat-list__items">
        <ChatListItem
          v-for="chat in inCommunicationTab ? entityChats : userChats"
          :key="chat.id"
          :chat="chat"
          :in-communication-tab="inCommunicationTab"
        />
      </div>
    </Section>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useChatStore } from '@/stores/chats'
import { useUserStore } from '@/stores/users'
import { storeToRefs } from 'pinia'
import Section from '@/components/ui/list/ListSection.vue'
import ChatListItem from '@/components/chat/ChatListItem.vue'
import { ChatEntityType, type ChatType } from '@/types/chatType'
import { WaimoIconButton, WaimoLoader } from '@finetic/waimo-ui'

const props = defineProps<{
  inCommunicationTab?: boolean
  subject?: ChatEntityType
  subjectId?: string
}>()

const { t } = useI18n()

/**
 * Default - on chat page: Load user's chats from store.
 */
const userStore = useUserStore()
const { me } = storeToRefs(userStore)

const chatStore = useChatStore()
const { getChatsByUserId } = storeToRefs(chatStore)
const userChats = computed(() => getChatsByUserId.value(me.value.id))

const loading = ref(false)

const loadNextPage = async () => {
  loading.value = true
  await chatStore.fetchNextPage().then(() => (loading.value = false))
}

if (!userChats.value.length) loadNextPage()

/**
 * Alternative - load chats for entity in chat tab on detall-view
 */
const { getChatsByTopicId } = storeToRefs(chatStore)
let entityChats: any = []

if (props.inCommunicationTab && props.subjectId) {
  entityChats = computed<ChatType[]>(() =>
    getChatsByTopicId.value(props.subjectId ?? ''),
  )
}

const loadNextTopicChatsPage = async () => {
  loading.value = true
  await chatStore
    .fetchChatsByTopicId(props.subjectId ?? '')
    .then(() => (loading.value = false))
}

if (props.subjectId && !entityChats.value.length) loadNextTopicChatsPage()

defineEmits(['addChat'])
</script>

<style scoped lang="scss">
.chat-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  min-width: 27.2rem;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &--in-communication-tab {
    padding: 1.2rem;
    border-radius: 0.4rem;
    background: var(--color-primary-10);
    width: 272px;

    :deep(h3) {
      color: var(--color-primary);
    }
  }
}
</style>
