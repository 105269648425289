import axios from 'axios'
import type { UserType, UserTypeFlat } from '@/types/userType'

export default class {
  static async getUsers({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<UserType[]>('users', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getUserById(id: string) {
    return axios.get<UserType>(`users/${id}`)
  }

  static async postUser(user: UserTypeFlat) {
    return axios.post<UserType>(`users`, user)
  }

  static async putUser(user: UserTypeFlat) {
    return axios.put<UserType>(`users/${user.id}`, user)
  }

  static async deleteUser(userId: string) {
    return axios.delete(`users/${userId}`)
  }

  static async getMe() {
    return axios.get<UserType>('users/me')
  }

  static async postCurrentEntity(newEntittyId: string | undefined) {
    return axios.post<string>('users/notify_entity', {
      entity: newEntittyId ?? null,
    })
  }

  static async fetchCurrentUserEntities() {
    return axios.get<Array<UserType & { entity: string }>>('users/overview')
  }
}
