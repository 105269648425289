<template>
  <Suspense>
    <slot />

    <template
      v-if="!error"
      #fallback
    >
      <WaimoLoader :size="Sizes.xxl" />
    </template>
  </Suspense>

  <WaimoEmpty
    v-if="error"
    :header="t('common.error')"
    image="/src/assets/img/empty-lists.svg"
  >
    {{ error }}
  </WaimoEmpty>
</template>

<script setup lang="ts">
import {
  Colors,
  Sizes,
  useToast,
  WaimoEmpty,
  WaimoLoader,
} from '@finetic/waimo-ui'
import { onErrorCaptured, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const toastService = useToast()

const error = ref('')

// TODO - change with go-live.

// Only show error msg in template and console on local dev env
// const baseUrl = import.meta.env.VITE_API_BASE_URL
const showErrorMessages =
  // baseUrl === 'https://dlp.ddev.site/api'
  true

onErrorCaptured((hook, target, info) => {
  if (showErrorMessages) {
    error.value = hook.message
    console.error(hook)
    console.error(target)
    console.error(info)
  }
  toastService.add({
    color: Colors.Danger,
    title: t('common.error'),
    message: `${hook.name}: ${hook.message}`,
  })
  return false
})
</script>

<style scoped lang="scss"></style>
