<template>
  <DefineTemplate v-slot="{ result, i }">
    <!-- TODO: DLP-690 - Add entity details like Avatar, TNR, BSN, AGB, percentages and numbers to EntityItem based on what entity it is -->
    <Transition name="slide-left-no-stagger-leave">
      <EntityItem
        :to="getRoute(result.relationType, result.id)"
        :style="{ '--i': i < 6 ? i - 3 : 4 }"
        :background="false"
        @click="addRecentlyVisited(result.id, result)"
      >
        <template #indicator>
          <WaimoIcon
            :type="IconType.Contained"
            icon="/icons/17-Users/10-Geomertic-Close Up-Single User-Neutral/single-neutral.svg"
          />
        </template>

        {{ result.name }}
      </EntityItem>
    </Transition>
  </DefineTemplate>

  <div class="search-overlay">
    <!-- Search input -->
    <WaimoHeaderSearch
      ref="headerSearchRef"
      v-model="searchQuery"
      class="search-overlay__input"
      :placeholder="t('header.search.title')"
      @keydown.esc.stop="
        (e: KeyboardEvent) => {
          ;(e.target as HTMLElement)?.blur()
          searchQuery = ''
          $emit('closeSearch')
        }
      "
      @keydown.tab.down.prevent.stop
      @keyup.tab.down.prevent.stop="resultsFound && activateFocusTrap()"
    />

    <!-- Results or previous queries and clicks -->
    <div class="search-overlay__inner">
      <transition name="slide-top">
        <div
          v-if="searchQuery?.length >= 2 && isLoading"
          class="loader loader--top"
        >
          <WaimoLoader />
        </div>
      </transition>

      <div
        ref="focusTrapRef"
        class="search-overlay__results"
      >
        <TransitionGroup name="slide-right">
          <!-- Recently searched -->
          <div
            v-if="!searchQuery || searchQuery.length < 2"
            class="list-section"
            :style="{ '--i': 3 }"
          >
            <h3>{{ t('header.search.recent.searched') }}</h3>
            <WaimoFlex
              :wrap="true"
              justify="start"
            >
              <WaimoTag
                v-for="(tag, index) in recentSearches"
                :key="index"
                class="search-overlay__tag"
                :color="Colors.Light"
                tabindex="0"
                @click="searchQuery = tag"
                @keydown.enter.stop="searchQuery = tag"
              >
                {{ tag }}
              </WaimoTag>
            </WaimoFlex>
          </div>
          <!-- / Recently searched -->

          <!-- Recently visited -->
          <div
            v-if="!searchQuery || searchQuery.length < 2"
            class="list-section"
            :style="{ '--i': 2 }"
          >
            <h3>{{ t('header.search.recent.viewed') }}</h3>
            <WaimoFlex direction="column">
              <template
                v-for="(result, i) in Array.from(recentVisits.values())"
                :key="i"
              >
                <ReuseTemplate v-bind="{ result, i }" />
              </template>
            </WaimoFlex>
          </div>
          <!-- / Recently visited -->

          <!-- New client -->
          <div
            v-if="!searchQuery || searchQuery.length < 2"
            class="list-section"
            :style="{ '--i': 1 }"
          >
            <router-link
              :to="{ name: 'ClientAdd' }"
              @click="$emit('closeSearch')"
            >
              {{ t('entities.client.actions.add') }}
            </router-link>
          </div>
          <!-- / New client -->
        </TransitionGroup>

        <WaimoEmpty
          v-if="!isLoading && searchQuery?.length >= 2 && !resultsFound"
          image="/src/assets/img/empty-lists.svg"
          :header="t('common.noResults')"
        />

        <TransitionGroup
          v-else
          name="slide-left-no-stagger-leave"
        >
          <!-- Search results -->
          <template v-if="searchQuery?.length >= 2">
            <template
              v-for="(entityGroup, type, index) in searchResults"
              :key="index"
            >
              <template v-if="entityGroup.length">
                <!-- List section -->
                <div
                  class="list-section"
                  :style="{ '--i': index }"
                >
                  <h3>
                    {{ t(`header.search.results.${type}`, 2) }}
                  </h3>

                  <WaimoFlex direction="column">
                    <template
                      v-for="(result, i) in entityGroup"
                      :key="result.id"
                    >
                      <ReuseTemplate
                        v-if="i < 10"
                        v-bind="{ result, i }"
                      />
                      <span v-if="i >= 10">
                        {{ t('header.search.results.more') }}
                      </span>
                    </template>
                  </WaimoFlex>
                </div>
                <!-- List section -->
              </template>
            </template>
          </template>
          <!-- Search results -->
        </TransitionGroup>
      </div>
      <!-- / Search overlay results -->
    </div>
    <!-- / Search overlay inner -->
  </div>
  <!-- / Search overlay -->
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import {
  Colors,
  IconType,
  WaimoEmpty,
  WaimoFlex,
  WaimoHeaderSearch,
  WaimoIcon,
  WaimoLoader,
  WaimoTag,
} from '@finetic/waimo-ui'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import {
  asyncComputed,
  createReusableTemplate,
  useDebounceFn,
  useLocalStorage,
  whenever,
} from '@vueuse/core'
import EntityItem from '@/components/ui/fullcircle/FullcircleEntityItem.vue'
import SearchService from '@/services/SearchService'
import {
  EntityTypeOf,
  type EntityRelation,
  type SearchResult,
} from '@/types/types'
import { isUuid } from '@/utils/regex'

const props = defineProps<{
  visible: boolean
}>()

const emits = defineEmits<{
  (e: 'closeSearch'): void
}>()

const { t } = useI18n()
const router = useRouter()

const searchQuery = ref('')
const isLoading = ref(false)
const resultsFound = ref(false)

const [DefineTemplate, ReuseTemplate] = createReusableTemplate<{
  i: number
  result: EntityRelation
}>()

/**
 * Auto-focus search-bar when search-overlay becomes active
 */
const headerSearchRef = ref<typeof WaimoHeaderSearch>()
onMounted(() => {
  headerSearchRef.value?.$el.querySelector('input')?.focus()
})

/**
 * Navigate through search results with arrow keys
 */
const focusTrapRef = ref<HTMLDivElement>()
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } =
  useFocusTrap(focusTrapRef, {
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    isKeyForward: (event) => {
      if (event.key === 'ArrowDown') event.preventDefault()
      return (
        (!event.shiftKey && event.key === 'Tab') || event.key === 'ArrowDown'
      )
    },
    isKeyBackward: (event) => {
      if (event.key === 'ArrowUp') event.preventDefault()
      return (event.shiftKey && event.key === 'Tab') || event.key === 'ArrowUp'
    },
    onDeactivate: () => {
      if (searchQuery.value) addRecentSearches(searchQuery.value)
    },
  })

whenever(
  () => !props.visible,
  () => deactivateFocusTrap({ returnFocus: false }),
)

/**
 * Search
 */
const searchFunction = useDebounceFn(
  async (search: string) => await SearchService.search(search),
  500,
)

const searchResults = asyncComputed(async () => {
  if (searchQuery.value.length < 2) return {} as SearchResult

  isLoading.value = true
  resultsFound.value = false

  const { data } = await searchFunction(searchQuery.value)

  if (Object.values(data).some((x) => x.length)) resultsFound.value = true
  isLoading.value = false

  return data
}, {} as SearchResult)

/**
 * Recent searches and visits
 */
const recentSearches = useLocalStorage<Set<string>>(
  'recent-searches',
  new Set<string>(),
)

const addRecentSearches = (value: string) => {
  recentSearches.value.delete(value)
  recentSearches.value = new Set<string>([
    value,
    ...Array.from(recentSearches.value.values()).slice(0, 4),
  ])
}

const recentVisits = useLocalStorage(
  'recent-visits',
  new Map<string, EntityRelation>(),
)

const addRecentlyVisited = (index: string, value: EntityRelation) => {
  recentVisits.value.delete(index)
  recentVisits.value = new Map([
    [index, value],
    ...Array.from(recentVisits.value.entries()).slice(0, 9),
  ])
}

/**
 * If search query is a uuid and only one result is found, immediately push route to that entity
 */
watch(searchQuery, (query) => {
  if (query?.match(isUuid)) {
    whenever(
      () => resultsFound.value,
      () => {
        let resultCounter = 0
        let entityType: EntityTypeOf
        let entity: EntityRelation

        setTimeout(() => {
          for (const [key, value] of Object.entries(searchResults.value)) {
            if (value.length) {
              // Weird: `key` is already of type `EntityTypeOf` but needs to be re-set here.
              entityType = key as EntityTypeOf

              // Should always be 1 result, so we can add the first entry from the array.
              // We check in an if-statement later to be sure.
              resultCounter += value.length
              entity = value[0]
            }
          }

          if (resultCounter === 1) {
            addRecentlyVisited(entity.id, entity)

            const route = getRoute(entityType, query)
            router.push(route)
          }
        }, 25)
      },
    )
  }
})

const getRoute = (type: EntityTypeOf, id: string) => {
  let routeName = ''

  switch (true) {
    case type === EntityTypeOf.CLIENT:
      routeName = 'ClientDetail'
      break
    case type === EntityTypeOf.ALLOCATION:
      routeName = 'AllocationDetail'
      break
    case type === EntityTypeOf.SUPPLIER:
      routeName = 'SupplierDetail'
      break
    case type === EntityTypeOf.PRODUCT:
      routeName = 'ProductDetail'
      break
    case type === EntityTypeOf.CONTRACT:
      routeName = 'ContractDetail'
      break
    case type === EntityTypeOf.CLAIM:
      routeName = 'ClaimDetail'
      break
  }

  return {
    name: routeName,
    params: { id: id },
  }
}

onBeforeRouteUpdate(() => {
  if (searchQuery.value && !searchQuery.value.match(isUuid)) {
    addRecentSearches(searchQuery.value)
  }

  searchQuery.value = ''
  setTimeout(() => emits('closeSearch'), 50)
})
</script>

<style scoped lang="scss">
.search-overlay {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__input {
    flex: 1;
    margin-bottom: 2.4rem;
    width: 100%;
    max-width: 480px;
  }

  &__inner {
    flex: auto;
    width: 100%;
    height: 100%;
    margin: 0 2.4rem;
    padding: 2.4rem 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2.4rem;
    overflow-y: scroll;
    border-top: 1px solid var(--color-dark-10);
    border-bottom: 1px solid var(--color-dark-10);
  }

  &__results {
    width: 100%;
    height: 100%;
    max-width: 480px;
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    & > *:last-child {
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1.6rem;
      }
    }
  }

  &__tag {
    &:hover {
      cursor: pointer;
      background-color: var(--color-primary-10);
    }
  }
}

.loader {
  &--top {
    position: absolute;
    top: 2.4rem;
  }
}

.list-section {
  width: 100%;

  &__show-more {
    color: var(--color-primary);

    &:hover {
      cursor: pointer;
    }
  }
}

.filter {
  text-align: right;

  &__heading {
    color: var(--color-primary);
    font-weight: 600;

    &:hover {
      cursor: pointer;
      color: var(--color-primary-200);
    }
  }

  &__list {
    list-style: none;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--color-dark-60);

    &:hover {
      cursor: pointer;
      color: var(--color-primary);
    }

    &--active {
      color: var(--color-dark);

      .filter__tag {
        background-color: var(--color-primary);
        color: var(--color-light);
      }
    }
  }

  &__tag {
    display: inline;
    margin: 0 0 0 1rem;
    padding: 0.4rem 0.8rem;
    color: var(--color-dark-60);
    background-color: var(--color-dark-10);
    border-radius: 1rem;
  }
}

// Vue Transition classes
.slide-top,
.slide-left,
.slide-left-no-stagger-leave,
.slide-right {
  &-leave-active {
    transition: 0.3s ease-in;
  }

  &-enter-active {
    transition: 0.3s ease-out;
  }

  &-enter-active,
  &-leave-active {
    transition-delay: calc(0.1s * (var(--i) - 1));
  }

  // .slide-right
  &-enter-from,
  &-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }
}

.slide-top {
  &-enter-from,
  &-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.slide-left,
.slide-left-no-stagger-leave {
  &-enter-from,
  &-leave-to {
    transform: translateX(-50px);
    opacity: 0;
  }
}

.slide-left-no-stagger-leave {
  &-leave-active {
    transition-delay: 0s;
  }
}
</style>
