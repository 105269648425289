import { useUserStore } from '@/stores/users'

export const defaultSettings = {
  seasonalDecoration: true,
}

export const loadSettings = async () => {
  const localSettings = localStorage.getItem('localSettings')

  let settings = defaultSettings
  if (localSettings) settings = JSON.parse(localSettings)

  Object.entries(settings).forEach(([key, value]) => {
    value
      ? document.documentElement.classList.add(key)
      : document.documentElement.classList.remove(key)
  })

  const userStore = useUserStore()
  await userStore.fetchMe()
  await userStore.getCurrentUserEntityIds()
}
