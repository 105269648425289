export enum EntityTypeOf {
  ALLOCATION = 'allocation',
  ALLOCATION_REQUEST = 'allocation_request',
  CHAT = 'chat',
  CLAIM = 'claim',
  CLIENT = 'client',
  CLIENT_ADDRESS = 'client_address',
  CLIENT_CONTACT = 'client_contact',
  COMPLAINT = 'complaint',
  CONTRACT = 'contract',
  CONTRACT_EXCLUSION = 'contract_exclusion',
  FULFILLMENT = 'fulfillment',
  IBERICHTEN = 'imessage',
  FILE = 'file',
  MUNICIPALITY = 'municipality',
  MUNICIPALITY_CONTACT = 'municipality_contact',
  PAYMENT = 'payment',
  PRODUCT_CONTRACT_PRICE = 'product_contract_price',
  PRODUCT_PRICE = 'product_price',
  PRODUCT_ROLE = 'product_role',
  QUOTATION = 'quotation',
  ROLE = 'role',
  SUPPLIER = 'supplier',
  SUPPLIER_CONTACT = 'supplier_contact',
  SUPPLIER_LOCATION = 'supplier_location',
  SUPPLIER_SPECIALIZATION = 'supplier_specialization',
  PRODUCT = 'product',
  USER = 'user',
}

export enum EntityAction {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export type EntityRelation = {
  relationType: EntityTypeOf
  name: string
  id: string
}

export enum EntityStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type SortBy = {
  field: string
  direction: 'ASC' | 'DESC'
}

export type SearchResult = Record<EntityTypeOf, EntityRelation[]>
