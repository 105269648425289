import { defineStore } from 'pinia'
import type { KnowledgeBaseArticle } from '@/types/knowledgeBaseType'
import KnowledgeBaseService from '@/services/KnowledgeBaseService'
import { isSupplier } from '@/utils/isAuthorized'

export const useKnowledgeBaseStore = defineStore({
  id: 'knowledgeBase',
  state: (): {
    data: KnowledgeBaseArticle[]
    tags: Set<string>
  } => ({
    data: [],
    tags: new Set(),
  }),
  actions: {
    clearKnowledgeBase() {
      this.data = []
      this.tags = new Set()
    },

    async fetchArticles() {
      const { data } = await KnowledgeBaseService.getArticles(this.tagsAsArray)

      this.data = data.data.sort((a, b) =>
        a.tags.length > b.tags.length
          ? -1
          : a.tags.length < b.tags.length
            ? 1
            : 0,
      )

      return this.data
    },

    async setTags(...tags: Array<string | string[]>) {
      this.tags = new Set(tags.flat())
      this.tags.add(isSupplier() ? 'gebruiker:aanbieder' : 'gebruiker:gemeente')

      await this.fetchArticles()
    },

    async addTags(...tags: Array<string | string[]>) {
      tags.flat().forEach((tag) => this.tags.add(tag))
      this.tags.add(isSupplier() ? 'gebruiker:aanbieder' : 'gebruiker:gemeente')

      await this.fetchArticles()
    },

    async removeTags(...tags: Array<string | string[]>) {
      tags.flat().forEach((tag) => this.tags.delete(tag))
      this.tags.add(isSupplier() ? 'gebruiker:aanbieder' : 'gebruiker:gemeente')

      await this.fetchArticles()
    },
  },
  getters: {
    tagsAsArray: (state) => Array.from<string>(state.tags),
  },
})
