<template>
  <div class="headeruser">
    <WaimoAvatar
      :first-name="me.firstname"
      :last-name="me.lastname"
      :image="me.profileImage"
      class="cursor-pointer"
      @click="overlayPanelRef?.toggle($event)"
    />
    <!-- z-index +1 on main menu -->
    <WaimoOverlayPanel
      ref="overlayPanelRef"
      align="start"
      class="d-flex"
      style="flex-direction: column; z-index: 1002"
    >
      <WaimoButton
        :color="Colors.Dark"
        :type="ButtonType.TextOnly"
        icon="/icons/01-Interface Essential/12-Settings/cog-double.svg"
        class="justify-start"
        @click="router.push({ name: 'ProfileSettings' })"
      >
        {{ t('profile.settings.header') }}
      </WaimoButton>
      <WaimoButton
        :color="Colors.Danger"
        :type="ButtonType.TextOnly"
        icon="/icons/01-Interface Essential/04-Login-Logout/logout-1.svg"
        class="justify-start"
        @click="$keycloak?.logoutFn?.()"
      >
        {{ t('common.logout') }}
      </WaimoButton>
    </WaimoOverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  ButtonType,
  Colors,
  WaimoAvatar,
  WaimoButton,
  WaimoOverlayPanel,
} from '@finetic/waimo-ui'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/users'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

const overlayPanelRef = ref<typeof WaimoOverlayPanel>()

const { t } = useI18n()
const router = useRouter()

const userStore = useUserStore()

const { me } = storeToRefs(userStore)
</script>

<style scoped lang="scss"></style>
