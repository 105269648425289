<template>
  <WaimoIconButton
    v-waimo-notification-badge.secondary.top-right="
      knowledgeBaseArticles.length
    "
    :type="IconButtonType.IconOnly"
    icon="/icons/11-Content/02-Books/book-flip-page.svg"
    :color="Colors.Light"
    @click="knowledgeBaseVisible = !knowledgeBaseVisible"
  />

  <WaimoSideDrawer
    v-model:visible="knowledgeBaseVisible"
    class="knowledge-base__sidedrawer"
    direction="left"
  >
    <WaimoFlex
      direction="column"
      style="height: 100%"
    >
      <CommonControlHeading
        :top-text="t('common.knowledgeBase')"
        :sub-text="
          t('header.knowledgeBase.articlesFound', knowledgeBaseArticles.length)
        "
      />
      <WaimoFlex
        style="flex-grow: 1"
        direction="column"
      >
        <template v-if="knowledgeBaseArticles.length">
          <WaimoAccordion
            v-for="article in knowledgeBaseArticles"
            :key="article.id"
            :title="article.title"
            class="knowledge-base__article"
          >
            <WaimoMarkdown
              :options="{ html: true, linkify: true }"
              :markdown="article.content"
            />
          </WaimoAccordion>
        </template>
        <WaimoEmpty
          v-else
          :header="t('common.noResults')"
          image="/src/assets/img/empty-lists.svg"
        />
      </WaimoFlex>
      <WaimoFlex
        v-if="isAdmin()"
        justify="end"
        :wrap="true"
      >
        <WaimoTag
          v-for="tag in tags"
          :key="tag"
          :color="Colors.Light"
          :copyable="true"
        >
          {{ tag }}
          <template #copied>
            {{ t('common.data.copied') }}
          </template>
        </WaimoTag>
      </WaimoFlex>
    </WaimoFlex>
  </WaimoSideDrawer>
</template>

<script setup lang="ts">
import {
  Colors,
  IconButtonType,
  WaimoAccordion,
  WaimoEmpty,
  WaimoFlex,
  WaimoIconButton,
  WaimoMarkdown,
  WaimoSideDrawer,
  WaimoTag,
} from '@finetic/waimo-ui'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useKnowledgeBaseStore } from '@/stores/knowledgeBase'
import { useI18n } from 'vue-i18n'
import CommonControlHeading from '@/components/ui/common/CommonControlHeading.vue'
import { isAdmin } from '@/utils/isAuthorized'

const { t } = useI18n()

const knowledgeBaseStore = useKnowledgeBaseStore()

const { data: knowledgeBaseArticles, tagsAsArray: tags } =
  storeToRefs(knowledgeBaseStore)

const knowledgeBaseVisible = ref(false)

defineExpose({
  knowledgeBaseVisible,
})

const emits = defineEmits<{
  (e: 'toggle-knowledgebase-active', val: boolean): void
}>()

watch(knowledgeBaseVisible, () =>
  emits('toggle-knowledgebase-active', knowledgeBaseVisible.value),
)
</script>

<style lang="scss">
.knowledge-base {
  &__sidedrawer {
    margin-left: 72px; // Offset main menu

    .waimo-dialog__content {
      scrollbar-gutter: stable;
    }
  }

  &__article * {
    max-width: 100%;
  }
}
</style>
