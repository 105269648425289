import axios from 'axios'
import { type TaskType } from '@/types/taskType'

export default class {
  static async getTasks({
    page = 1,
    pageSize = 999, // FIXME: Should be 30, wait for task pagination combined with sorting fix - DLP-1152
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<TaskType[]>('tasks', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getTaskById(id: string) {
    return axios.get<TaskType>(`tasks/${id}`)
  }

  static async putTask(task: TaskType) {
    return axios.put<TaskType>(`tasks/${task.id}`, task)
  }
}
