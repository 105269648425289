import type { KnowledgeBaseArticle } from '@/types/knowledgeBaseType'
import axios from 'axios'
import qs from 'qs'

const axiosCms = axios.create()
axiosCms.defaults.baseURL = import.meta.env.VITE_CMS_BASE_URL
axiosCms.defaults.paramsSerializer = (p) =>
  qs.stringify(p, { arrayFormat: 'comma' })

export default class {
  static async getArticles(tags: string[]) {
    return axiosCms.get<{ data: KnowledgeBaseArticle[] }>('waimo/articles', {
      params: { tags },
    })
  }
}
