import { defineStore } from 'pinia'

export const useGeneralStore = defineStore({
  id: 'general',
  state: (): {
    searchOverlayActive: boolean
    networkError?: { title: string, message: string }
  } => ({
    searchOverlayActive: false,
    networkError: undefined,
  }),
  actions: {
    setNetworkError(error: typeof this.networkError) {
      this.networkError = error
    },
  },
  getters: {
    getNetworkError: (state) => state.networkError,
  },
})
