<template>
  <div class="headernav">
    <ul>
      <li>
        <WaimoNavigationButton
          v-waimo-notification-badge.secondary.top-right="me.unreadTasks"
          icon="/icons/06-Business-Products/01-Business/briefcase.svg"
          :title="t('header.navigation.tasks')"
          :active="sidePanelState === SidePanelView.TASKS"
          @click="sidePanelState = SidePanelView.TASKS"
        />
      </li>
      <li>
        <WaimoNavigationButton
          v-waimo-notification-badge.secondary.top-right="me.unreadChatMessages"
          icon="/icons/21-Messages-Chat-Smileys/02-Messages-Speech-Bubbles/messages-bubble.svg"
          :title="t('header.navigation.chat')"
          :active="sidePanelState === SidePanelView.CHATS"
          @click="sidePanelState = SidePanelView.CHATS"
        >
        </WaimoNavigationButton>
      </li>
      <li>
        <WaimoNavigationButton
          icon="/icons/07-Work-Office-Companies/06-Office/office-drawer.svg"
          :title="t('header.navigation.lists')"
          :active="sidePanelState === SidePanelView.LISTS"
          @click="sidePanelState = SidePanelView.LISTS"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { WaimoNavigationButton } from '@finetic/waimo-ui'
import { useI18n } from 'vue-i18n'
import { useSessionStorage } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { SidePanelView } from '@/components/layout/DashboardLayout.vue'
import { useUserStore } from '@/stores/users'

const { t } = useI18n()

const sidePanelState = useSessionStorage('side-panel', SidePanelView.TASKS)

const userStore = useUserStore()
const { me } = storeToRefs(userStore)
</script>

<style scoped lang="scss">
.headernav {
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
</style>
