<template>
  <WaimoNavigationButton
    icon="/icons/01-Interface Essential/25-Search/search.svg"
    :title="t('header.navigation.search')"
    :active="searchOverlayActive"
    @click="searchOverlayActive = !searchOverlayActive"
  />

  <WaimoSideDrawer
    v-model:visible="searchOverlayActive"
    class="search__sidedrawer"
    direction="left"
  >
    <HeaderSearchOverlay
      v-model:visible="searchOverlayActive"
      @close-search="searchOverlayActive = false"
    />
  </WaimoSideDrawer>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { WaimoNavigationButton, WaimoSideDrawer } from '@finetic/waimo-ui'
import { useI18n } from 'vue-i18n'
import { useMagicKeys, whenever } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import HeaderSearchOverlay from '@/components/ui/header/HeaderSearchOverlay.vue'
import { useGeneralStore } from '@/stores/general'

const { t } = useI18n()

const generalStore = useGeneralStore()
const { searchOverlayActive } = storeToRefs(generalStore)

const emits = defineEmits<{
  (e: 'toggle-search-active', val: boolean): void
}>()

watch(searchOverlayActive, () =>
  emits('toggle-search-active', searchOverlayActive.value),
)

const { ctrl_k } = useMagicKeys()
whenever(ctrl_k, () => {
  searchOverlayActive.value = true
})

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault()
    }
  })
})
</script>

<style lang="scss">
.search {
  &__sidedrawer {
    margin-left: 72px; // Offset main menu
  }
}
</style>
