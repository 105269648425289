<template>
  <div class="dashboard-layout">
    <div class="dashboard-layout__sidepane">
      <div class="dashboard-layout__municipality">
        <!-- TODO: Show user's main municipality's logo and place municipality's name in alt-tag. -->
        <img
          class="dashboard-layout__municipality-logo"
          src="@/assets/img/regiogv-logo-fc-rgb--text-only-300x61.png"
          alt="Logo gemeente"
        />
      </div>

      <div
        id="sidepane-left"
        class="dashboard-layout__sidepane--left"
        @scroll="checkIfScrolledToBottom"
      >
        <CommonSuspenseWithToast>
          <Transition name="fade">
            <ListNavigation v-if="sidePanelState === SidePanelView.LISTS" />
            <TaskList
              v-else-if="sidePanelState === SidePanelView.TASKS"
              ref="taskListRef"
            />
            <ChatList v-else-if="sidePanelState === SidePanelView.CHATS" />
          </Transition>
        </CommonSuspenseWithToast>
      </div>
    </div>
    <div class="dashboard-layout__content">
      <slot />
    </div>
    <div
      id="sidepane-right"
      class="dashboard-layout__sidepane dashboard-layout__sidepane--right"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CommonSuspenseWithToast from '@/components/ui/common/CommonSuspenseWithToast.vue'
import ListNavigation from '@/components/list/ListNavigation.vue'
import TaskList from '@/components/task/TaskList.vue'
import ChatList from '@/components/chat/ChatList.vue'
import { useSessionStorage } from '@vueuse/core'

const taskListRef = ref()

const sidePanelState = useSessionStorage('side-panel', SidePanelView.TASKS)

type ScrollTarget = {
  target: {
    scrollTop: number
    clientHeight: number
    scrollHeight: number
  }
}

const checkIfScrolledToBottom = ({
  target: { scrollTop, clientHeight, scrollHeight },
}: ScrollTarget | any) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    taskListRef.value?.loadNextPage()
  }
}
</script>

<script lang="ts">
export enum SidePanelView {
  LISTS = 'lists',
  TASKS = 'tasks',
  CHATS = 'chats',
}
</script>

<style scoped lang="scss">
@use '@/scss/mixins' as *;

.dashboard-layout {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 300px 1fr 400px;
  grid-template-areas: 'list content side';
  height: 100%;

  &__content {
    height: 100%;
    overflow: hidden;
    background: var(--color-light);
    grid-area: content;
  }

  &__sidepane {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: auto;

    &--left,
    &--right {
      height: 100%;
      flex: auto;
      overflow-y: auto;
      @include scrollbar-on-hover;
      grid-area: list;
      scrollbar-gutter: stable;
      align-items: stretch;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 16px;
      }
    }

    &--left {
      flex: 1;
      padding: 2.4rem 1.2rem;

      &:after {
        right: 0;
        background: linear-gradient(
          -90deg,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        );
      }
    }

    &--right {
      grid-area: side;

      &:before {
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }

  &__municipality {
    width: 100%;
    height: 7.5rem;
    padding: 1.5rem 2.4rem 1.2rem 2.4rem;
    background-color: var(--color-background-200);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__municipality-logo {
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 1366px) {
  .dashboard-layout {
    grid-template-columns: 200px 1fr 250px;
  }
}

// Vue Transition classes
.fade-enter-active,
.fade-leave-active {
  transition: opacity 100ms ease;
}

.fade-leave-active {
  height: 0;
  overflow: visible;
}
</style>
