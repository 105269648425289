import { useTaskStore } from '@/stores/tasks'
import { useChatStore } from '@/stores/chats'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/users'
import { EntityTypeOf, EntityAction } from '@/types/types'
import type { UserType } from '@/types/userType'

const baseUrl = import.meta.env.VITE_MERCURE_BASE_URL

/**
 * Subscribes to Mercure topic
 *
 * @param {string} topic - URL for topic
 * @returns {EventSource} - Published update, every time an update is published
 */
export const mercureSubscribe = (topic: string) => {
  const url = new URL(`${baseUrl}.well-known/mercure`)
  url.searchParams.append('topic', topic)

  return new EventSource(url)
}

/**
 * Unsubscribes from Mercure topic
 *
 * @param {EventSource} eventSource - The EventSource to unsubscribe from
 */
export const mercureUnsubscribe = (eventSource: EventSource) => {
  eventSource.close()
}

/**
 * Initialize all nessecary Mercure subscriptions. To be used in `main.ts`
 */
const initMercureSubscriptions = () => {
  const userCurrentEntity = mercureSubscribe('user/current_entity')
  userCurrentEntity.onmessage = (e) => {
    const data: UserType & { entity: string | null } = JSON.parse(e.data)

    const userStore = useUserStore()
    userStore.updateCurrentUserEntityIds(data)
  }

  const taskList = mercureSubscribe('task/list')
  taskList.onmessage = (e) => {
    if (JSON.parse(e.data).changed) {
      useTaskStore().syncTasks()
    }
  }

  /**
   * If a user is currently on an entity that has been changed, re-run api call to get new data.
   * If a user is currently on a task that is no longer active, redirect user to tasks overview
   */
  const entityChanged = mercureSubscribe('entity/changed')
  entityChanged.onmessage = (e) => {
    const data = JSON.parse(e.data)
    const action = data.action
    const type = data.type
    const changedId = data.id

    const userStore = useUserStore()
    const { findMyCurrentEntityId } = storeToRefs(userStore)
    const currentEntityId = findMyCurrentEntityId.value

    const chatStore = useChatStore()

    const getEntity = (type: EntityTypeOf, id: string): void => {
      switch (type) {
        case EntityTypeOf.CHAT:
          chatStore.fetchChatById(id, false, true)
          userStore.fetchMe()
      }
      // TODO - set up other entity types
    }

    const syncEntity = (
      type: EntityTypeOf,
      id: string,
      watching: boolean,
    ): void => {
      switch (type) {
        case EntityTypeOf.CHAT:
          chatStore.fetchNewChatMessagesByChatId(id, watching)
          userStore.fetchMe()
      }
      // TODO - set up other entity types
    }

    switch (action) {
      case EntityAction.CREATED:
        getEntity(type, changedId)
        break
      case EntityAction.UPDATED:
        syncEntity(type, changedId, changedId === currentEntityId)
        break
      case EntityAction.DELETED:
        if (changedId === currentEntityId)
          userStore.setCurrentEntityDeletedWarning(true)
        break
    }
  }
}

export default initMercureSubscriptions
