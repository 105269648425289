import axios from 'axios'
import {
  ChatEntityType,
  type ChatMessage,
  type ChatMessageDTO,
  type ChatType,
  type ChatUserType,
  type NewChat,
} from '@/types/chatType'

export default class {
  static async getChats({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ChatType[]>('chats', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getChatById(id: string) {
    return axios.get<ChatType>(`chats/${id}`)
  }

  static async fetchChatsByTopicId(topicId: string) {
    return axios.get<ChatType[]>(`chats/topic/${topicId}`)
  }

  static async getChatMessagesByChatId(id: string, page: number) {
    return axios.get<ChatMessage[]>(`chats/${id}/messages`, {
      params: {
        page: page,
      },
    })
  }

  static async getAllowedUsers(entityType: ChatEntityType, entityId: string) {
    return axios.get<ChatUserType[]>(`chats/users/${entityType}/${entityId}`, {
      params: {
        itemsPerPage: 999,
      },
    })
  }

  static async addMessageToChat(chatId: string, message: ChatMessageDTO) {
    return axios.post<ChatMessage>(`chats/${chatId}/messages`, message)
  }

  static async addNewChat(chat: NewChat) {
    return axios.post<ChatType>(`chats`, chat)
  }
}
