<script setup lang="ts">
import { WaimoFlex } from '@finetic/waimo-ui'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    text?: string
    offset?: number
  }>(),
  {
    text: '',
    offset: 0,
  },
)

const style = computed(() => [{ 'padding-left': props.offset + 'px' }])
</script>

<template>
  <div class="section">
    <div class="section__header">
      <WaimoFlex
        align="center"
        justify="between"
      >
        <h3
          v-if="text"
          :style="style"
        >
          {{ text }}
        </h3>
        <slot name="headerRight" />
      </WaimoFlex>
    </div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.section {
  &__header {
    margin-bottom: 1.2rem;

    h3 {
      color: var(--color-light);
      font-size: 1.2rem;
      text-transform: uppercase;
      margin: 0;
    }
  }
}
</style>
