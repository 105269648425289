<template>
  <WaimoToastProvider />
  <WaimoDialogProvider />
  <RouterView />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import {
  Colors,
  WaimoDialogProvider,
  WaimoToastProvider,
  useDialog,
  useToast,
} from '@finetic/waimo-ui'
import { useUserStore } from '@/stores/users'
import { useGeneralStore } from '@/stores/general'
import { loadSettings } from '@/utils/settings'
import { useI18n } from 'vue-i18n'
import { setLocale } from 'yup'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

loadSettings()

const { t, d } = useI18n()
const dialogService = useDialog()
const toastService = useToast()
const router = useRouter()

const userStore = useUserStore()
const generalStore = useGeneralStore()

// Dialog for deleted entity
const { currentEntityDeletedWarning } = storeToRefs(userStore)
const showDialog = computed(() => currentEntityDeletedWarning.value)

watch(showDialog, async () => {
  if (
    showDialog.value &&
    (await dialogService.show({
      title: t('dialog.deleted.title'),
      message: t('dialog.deleted.message'),
      dismissible: false,
      cancelText: '',
    }))
  ) {
    router.replace({ name: 'Home' })
    userStore.setCurrentEntityDeletedWarning(false)
  }
})

// Toast for network error
const { getNetworkError } = storeToRefs(generalStore)

watch(getNetworkError, () => {
  if (getNetworkError?.value) {
    toastService.add({
      color: Colors.Danger,
      title: getNetworkError.value.title,
      message: getNetworkError.value.message,
    })
  }

  generalStore.setNetworkError(undefined)
})

// Yup form validator error messages override
setLocale({
  date: {
    min: ({ min, label }) =>
      t('form.validation.date.min', {
        label: label,
        date: d(min, 'date'),
      }),
    max: ({ max, label }) =>
      t('form.validation.date.max', {
        label: label,
        date: d(max, 'date'),
      }),
  },
})
</script>
