<template>
  <router-link
    class="taskitem"
    :class="{ 'taskitem--unread': !task.read }"
    :to="{ name: 'TaskDetail', params: { id: task.id } }"
  >
    <div class="taskitem__header">
      <span>{{ t(`tasks.list.item.title.${task.type}`) }}</span>
      <span
        class="taskitem__date"
        :title="createdAt"
        :class="{ 'taskitem__date--alert': task.priority }"
      >
        {{ dateDistanceToNow(task.createdAt) }}
      </span>
    </div>
    <div
      v-for="(subtitle, i) in task.titleDescriptions"
      :key="i"
      class="taskitem__subtitle"
    >
      {{ getSubtitle(subtitle) }}
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  TaskDescriptionContext,
  type TaskDescription,
  type TaskType,
} from '@/types/taskType'
import { dateDistanceToNow } from '@/utils/utils'

const { t, locale } = useI18n()

const props = defineProps<{
  task: TaskType
}>()

const createdAt = new Date(props.task.createdAt).toLocaleString(locale.value, {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
})

const getSubtitle = (subtitle: TaskDescription): string | number => {
  switch (subtitle.context) {
    case TaskDescriptionContext.PRODUCT_NAME:
      return subtitle.values.product ?? ''

    case TaskDescriptionContext.SUPPLIER_NAME:
      return subtitle.values.supplier ?? ''

    case TaskDescriptionContext.USERNAME:
      return subtitle.values.email ?? ''

    case TaskDescriptionContext.ORGANISATION:
      return subtitle.values.organisation ?? ''

    case TaskDescriptionContext.ALLOCATION_NUMBER:
      return subtitle.values.allocation_number ?? ''

    case TaskDescriptionContext.FULFILLMENT_NUMBER:
      return subtitle.values.fulfillment_number ?? ''

    case TaskDescriptionContext.MUNICIPALITY_NAME:
      return subtitle.values.municipality_name ?? ''
  }

  return ''
}
</script>

<style scoped lang="scss">
.taskitem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background-color: transparent;
  padding: 1.2rem 1.2rem 1.2rem 2.4rem;
  color: var(--color-light);
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 0.4rem;

  &.router-link-active {
    background-color: var(--color-primary);
  }

  &:hover {
    background-color: var(--color-background-80);
    text-decoration: none;
  }

  &--unread {
    &::before {
      content: '';
      background-color: var(--color-light);
      position: absolute;
      top: 1.6rem;
      left: 1.2rem;
      width: 8px;
      height: 8px;
      border-radius: 5px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    font-weight: 500;
  }

  &__subtitle {
    color: var(--color-light);
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__date {
    text-align: right;
    color: var(--color-background-60);

    &--alert {
      &::before {
        content: '';
        float: left;
        width: 1.2rem;
        height: 1.2rem;
        background-color: var(--color-light);
        mask-image: url('/icons/01-Interface Essential/14-Alerts/alert-triangle.svg');
        -webkit-mask-image: url('/icons/01-Interface Essential/14-Alerts/alert-triangle.svg');
        margin: 0.3rem 0.4rem 0 0;
      }
    }
  }
}
</style>
